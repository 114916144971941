import React, { useEffect, useMemo, useState, useRef } from 'react';
import { observer, inject } from 'mobx-react';
import { toJS } from 'mobx';
import cx from 'classnames';

import CharityDetailsModal from 'components/modals/CharityDetailsModal';

import useSearch from 'hooks/useSearch';
import withUserContext from 'behaviors/withUserContext';
import { EventListWithArrowsAbove } from 'components/EventList/EventList';
import useDonationLanding from 'hooks/useDonationLanding';
import useProfileById from 'hooks/useProfileById';
import TopicList from 'components/TopicList/TopicList';
import useLocalFeedStore from 'stores/LocalFeedStore';

import { PopularNonprofitsSection } from './components';
import GlobalFeed from 'components/Feed/GlobalFeed';
import useDashboardStore from 'stores/DashboardStore';
import StartCauzeButton from 'components/Button/StartCauzeButton';
import useMobile from 'hooks/useMobile';

const DiscoverView = ({
  activeEntity,
  searchStore,
  uiStore,
  donationLandingStore,
  userProfileStore,
  authStore,
}) => {
  const feedSectionRef = useRef(null);
  const { isLaptop } = useMobile();
  useDonationLanding();

  const userId =
    activeEntity?.userContext?.userId ||
    activeEntity?.userContext?.influencerId;

  useProfileById({
    id: userId,
    userContext: activeEntity?.userContext,
  });
  useSearch();

  const user = userProfileStore.users.get(+userId);
  const {
    getLocalNonprofits,
    getParsedLocation,
    localEntities,
    parsedLocationString,
  } = useLocalFeedStore();

  const { setProps } = useDashboardStore();

  const [detailCharity, setDetailCharity] = useState(null);
  const [showDetails, setShowDetails] = useState(false);

  const [localLoading, setLocalLoading] = useState(true);
  const [zipCode, setZipCode] = useState(user?.zip);

  const topics = useMemo(() => {
    searchStore.topics.concat(searchStore.categories);

    const _topics = toJS(searchStore.topics);
    const _categories = toJS(searchStore.categories);

    return [..._topics, ..._categories];
  }, [searchStore.topics, searchStore.categories]);

  useEffect(() => {
    setLocalLoading(true);
    Promise.all(
      zipCode
        ? [
            getParsedLocation({ location: zipCode }),
            getLocalNonprofits({ location: zipCode }),
          ]
        : [],
    ).finally(() => {
      setLocalLoading(false);
    });
  }, [zipCode, getLocalNonprofits]);

  useEffect(() => {
    setZipCode(user?.zip);
  }, [user]);

  useEffect(() => {
    setProps({
      size: 'half',
      title: 'Discover',
      asideChildren: authStore.isAuthenticated && isLaptop && (
        <div className={cx('xl:block')}>
          <div className="flex flex-col gap-y-[22px] mt-0 pt-0 px-4 lg:pt-12 lg:px-0">
            <StartCauzeButton activeEntity={activeEntity} />
          </div>
        </div>
      ),
    });
  }, []);

  return (
    <div className="search-view flex-column">
      <div className="mb-5">
        {showDetails && (
          <CharityDetailsModal
            isOpen={showDetails}
            mini
            onToggleClose={() => {
              setShowDetails(false);
              setDetailCharity(null);
            }}
            charity={detailCharity}
          />
        )}

        <TopicList
          title="Trending Topics"
          topics={topics}
          loading={searchStore.loading}
        />
        {!authStore.isAuthenticated && (
          <div ref={feedSectionRef} className="h-0 w-0"></div>
        )}
        <EventListWithArrowsAbove
          title="Join Popular Cauzes"
          events={donationLandingStore.topCauzes}
          loading={donationLandingStore.loadingCauzes}
          uiStore={uiStore}
          className="mt-8"
        />
        {authStore.isAuthenticated && isLaptop && (
          <StartCauzeButton activeEntity={activeEntity} />
        )}

        {authStore.isAuthenticated && (
          <div ref={feedSectionRef} className="h-0 w-0"></div>
        )}

        <PopularNonprofitsSection
          user={user}
          localEntities={localEntities}
          activeEntity={activeEntity}
          localLoading={localLoading}
          userLoading={userProfileStore.loading}
          parsedLocationString={parsedLocationString}
          onApply={(newZip) => {
            setZipCode(newZip);
            userProfileStore.updateZipFromParsedLocation({
              location: newZip,
              id: user?.id,
            });
          }}
        />
      </div>
      <GlobalFeed
        feedSectionRef={feedSectionRef}
        activeEntity={activeEntity}
        title="Global Feed"
        onCheckoutSuccess={() => {
          donationLandingStore.getRecentFeedItems(true);
        }}
      />
    </div>
  );
};

export default withUserContext(
  inject(
    'searchStore',
    'donationLandingStore',
    'uiStore',
    'authStore',
    'charityStore',
    'localFeedStore',
    'userProfileStore',
    'profileStore',
  )(observer(DiscoverView)),
);
