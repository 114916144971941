import React, { useState, useEffect } from 'react';
import { navigate } from '@reach/router';
import mixpanel from 'mixpanel-browser';
import cx from 'classnames';
import { inject, observer } from 'mobx-react';
import dompurify from 'dompurify';

import Avatar from 'components/Avatar/Avatar';
import FeedCardMatch from './FeedCardMatch';

import { isSameEntity } from 'util/entity';

import ActorVerbSubjectText from 'components/ActorVerbSubjectText';
import FeedCardCauze from './FeedCardCauze';
import FeedItemActionMenu from './FeedItemActionMenu';

import FeedItemActions from './FeedItemActions';
import { shareImage } from 'util/share';
import useMobile from 'hooks/useMobile';

const FeedItemComment = ({ comment }) => {
  const commentMaxCount = 200;
  const [hideMore, setHideMore] = useState(comment?.length > commentMaxCount);
  const sanitizer = dompurify.sanitize;

  useEffect(() => {
    function handleToggle(e) {
      if (
        e.target.id === 'feed-item-read-more' ||
        e.target.id === 'feed-item-read-less'
      ) {
        setHideMore((prev) => !prev);
      }
    }
    window.addEventListener('click', handleToggle);
    return () => window.removeEventListener('click', handleToggle);
  }, []);

  if (!comment) return null;

  const truncatedComment = hideMore
    ? comment.slice(0, commentMaxCount)
    : comment;
  const showToggleButton = comment.length > commentMaxCount;

  return (
    <div
      className="feed-item-comment"
      dangerouslySetInnerHTML={{
        __html: sanitizer(
          `${truncatedComment}${
            showToggleButton
              ? hideMore
                ? `...<span><span id="feed-item-read-more" class="read-more-button font-agenda-bold">View More</span></span>`
                : `<span><span id="feed-item-read-less" class="read-more-button font-agenda-bold">View Less</span></span>`
              : ''
          }`,
        ),
      }}
    />
  );
};

const FeedItem = ({
  item,
  onLike,
  eventTitle = '',
  entity,
  index,
  uiStore,
  followingFeedStore,
  authStore,
  profileStore,
  onCheckoutSuccess,
  scrollToFeed = () => {},
  isCauzePage = false,
}) => {
  let imageHref;
  const { isMobile } = useMobile();
  const [commentCount, setCommentCount] = useState(item?.commentCount || 0);
  const [currentEntityCommented, setCurrentEntityCommented] = useState(
    (item?.currentEntityCommented && authStore.isAuthenticated) || false,
  );

  const purchaseId = item.purchaseId;
  const eventId = item.eventId;
  const entityType = item.actor?.entityType;

  const subject = item.subjects?.[0];
  const event = item.event;
  const shareLink = event?.shareLink || item?.activityPurchase?.shareLink;
  const showEvent = event && event.eventType?.toLowerCase() === 'planned';
  const hasComment = Boolean(item?.purchaseComment);
  const isJoinedEntity =
    item?.activityEntity?.id === item?.joinedEntity?.id &&
    item?.activityEntity?.type === item?.joinedEntity?.type;
  const isJoinedActivity =
    item?.itemType === 'JOINED_ACTIVITY' &&
    item?.joinedEntity &&
    !isJoinedEntity &&
    authStore.isAuthenticated;

  if (eventId) {
    imageHref = `/event/${eventId}${
      purchaseId ? `?joined_purchase_id=${purchaseId}` : ''
    }`;
  } else if (purchaseId) {
    imageHref = `/event/purchase/${purchaseId}${
      purchaseId ? `?joined_purchase_id=${purchaseId}` : ''
    }`;
  } else if (item.subjects?.[0]?.id) {
    imageHref = `/event/${item.subjects[0].id}${
      purchaseId ? `?joined_purchase_id=${purchaseId}` : ''
    }`;
  }

  const onJoinClick = async () => {
    mixpanel.track('Feed Item Join Click', {
      ...item,
    });

    uiStore.openModal('CHECKOUT', {
      checkoutType: 'donation',
      onCheckoutSuccess,
      joinedEntity:
        !isSameEntity(item?.activityEntity, profileStore?.activeEntity) &&
        item?.activityEntity,
      eventId: eventId,
      scrollToFeed: scrollToFeed,
      charityIds: item?.subjects
        ?.filter((subject) => subject.__typename === 'Project')
        ?.map((subject) => subject.id),
      joinedPurchaseId: purchaseId,
    });
  };

  const onShareClick = () => {
    if (isMobile && navigator.share) {
      if (event?.image) {
        shareImage({
          url: shareLink,
          imageUrl: event.image.full,
          title: event.name,
          text: event.description,
        }).catch((_err) => {
          navigator
            .share({
              title: event?.name || 'Cauze Donation',
              url: shareLink,
            })
            .catch((err) => {
              if (err.message !== 'Share canceled') {
                uiStore.openModal('SHARE_MODAL', {
                  shareUrl: shareLink,
                  isSelf: false,
                });
              }
            });
        });
      } else {
        navigator.share({
          title: event?.name || 'Cauze Donation',
          url: shareLink,
        });
      }
    } else {
      uiStore.openModal('SHARE_MODAL', {
        shareUrl: shareLink,
        isSelf: false,
      });
    }
  };

  const setFeedCount = (count, currentEntityCommented) => {
    setCommentCount(count);
    setCurrentEntityCommented(currentEntityCommented);
    uiStore.openModal('COMMENT', {
      commentProps: {
        purchaseId,
        feed: item,
        index: index,
        currentEntityCommented: currentEntityCommented,
        commentCount: count,
        setFeedCount,
        followingFeedStore: followingFeedStore,
      },
    });
  };

  const onCommentClick = () => {
    if (!authStore.isAuthenticated) {
      uiStore.openModal('CONFIRM', {
        message: 'Please Login or Signup to like this post.',
        action: () => {
          navigate('/login');
        },
        confirmText: 'Login/Signup',
        cancelText: 'Not Now',
        bodyMessage:
          'Create your own Cauze account and join our community of givers.',
      });
      return;
    }
    uiStore.openModal('COMMENT', {
      commentProps: {
        purchaseId,
        feed: item,
        index: index,
        currentEntityCommented,
        commentCount,
        setFeedCount,
        followingFeedStore: followingFeedStore,
      },
    });
  };

  const isInactiveUser =
    entityType === 'USER' &&
    (!item.actor?.user?.isActive || item.actor?.user?.unclaimed);
  const AvatarContainer = isInactiveUser ? 'div' : 'a';
  const isMatchSponsor = Boolean(
    Array.isArray(item?.matchActors) &&
      item.matchActors.find(
        (matchActor) =>
          `${matchActor.id}` === `${entity?.id}` &&
          matchActor.entityType === `${entity?.groupType}`.toUpperCase(),
      ),
  );

  const hasMatch = Boolean(item.matchActors?.length) && !isMatchSponsor;

  let avatarEntity = {
    id: item.actor?.id,
    entityType: entityType,
    avatar: item.actor?.avatar,
  };

  if (isMatchSponsor) {
    avatarEntity = {
      id: entity.id,
      entityType: `${entity?.groupType}`.toUpperCase(),
      avatar: entity.avatar,
    };
  }

  const isImageExistWithCauze =
    subject && (subject.image?.lg || subject.previewUrl);
  return (
    <div className="feed-item">
      <div className={cx('feed-item-title ')}>
        <AvatarContainer
          className="my-auto h-10"
          onClick={() => {
            if (!isInactiveUser) {
              uiStore.openModal('PROFILE_MODAL', {
                id: avatarEntity.id,
                entityType: avatarEntity.entityType.toUpperCase(),
              });
            }
          }}
        >
          <Avatar
            avatar={avatarEntity.avatar}
            authStore
            entityType={avatarEntity.entityType}
            id={avatarEntity.id}
            onClick={null}
            noBorder
          />
        </AvatarContainer>
        <div className="my-auto leading-none">
          <ActorVerbSubjectText
            {...item}
            isCauzePage={isCauzePage}
            isAuthenticated={authStore.isAuthenticated}
            eventTitle={eventTitle}
            entity={entity}
            event={event}
            isMatchSponsor={isMatchSponsor}
          />
        </div>
        <div className="flex ml-auto cursor-pointer p-0 bg-transparent border-none">
          <FeedItemActionMenu
            uiStore={uiStore}
            purchaseId={purchaseId}
            isCurrentEntity={item?.activityEntity?.isCurrentEntity}
          />
        </div>
      </div>
      {showEvent && isImageExistWithCauze && (
        <div className="feed-card-cauze-container">
          <div className="feed-card-cauze-lines" />
          <FeedCardCauze
            href={imageHref}
            event={{
              ...subject,
            }}
            hasComment={hasComment || hasMatch}
          />
        </div>
      )}
      {hasMatch && (
        <div
          className={cx('feed-item-matches', {
            'has-comment': hasComment && showEvent,
          })}
        >
          <div
            className={cx('feed-item-actions-lines', {
              'has-comment': !showEvent,
            })}
          />
          <FeedCardMatch uiStore={uiStore} matches={item.matchActors} />
        </div>
      )}
      {hasComment ? (
        <div
          className={cx('feed-item-comment-container mb-2', {
            'no-event': !showEvent,
          })}
        >
          {isImageExistWithCauze ? (
            <div
              className={cx('feed-item-actions-lines', {
                'has-comment': !showEvent,
              })}
            />
          ) : (
            <div className="pl-12" />
          )}
          <FeedItemComment comment={item?.purchaseComment} />
        </div>
      ) : (
        <></>
      )}
      <FeedItemActions
        hasComment={hasComment}
        showEvent={showEvent}
        hasMatch={hasMatch}
        isImageExistWithCauze={isImageExistWithCauze}
        onJoinClick={onJoinClick}
        isJoinedActivity={isJoinedActivity}
        item={item}
        commentCount={commentCount}
        onCommentClick={onCommentClick}
        currentEntityCommented={currentEntityCommented}
        authStore={authStore}
        onLike={onLike}
        onShareClick={shareLink ? onShareClick : null}
      />
    </div>
  );
};

export default inject(
  'authStore',
  'uiStore',
  'profileStore',
  'donationLandingStore',
)(observer(FeedItem));
