import { useState } from 'react';
import { inject, observer } from 'mobx-react';
import { navigate } from '@reach/router';
import moment from 'moment';

import { ReactComponent as ThreeDotsIcon } from 'assets/images/icons/dots/three-dots.svg';
import Avatar from 'components/Avatar/Avatar';
import { ReactComponent as PinIcon } from 'assets/images/icons/svgs/pin-outline-bold.svg';
import { getEntityUrl } from 'util/navHelpers';
import useFollowingFeedStore from 'stores/FollowingFeedStore';
import FeedItemActions from './FeedItemActions';
import { usePopperTooltip } from 'react-popper-tooltip';

const EventHostUpdates = ({
  host,
  item,
  onLike,
  authStore,
  uiStore,
  onShareClick,
  eventStore,
  event,
}) => {
  const purchase = item.activityPurchase;
  const followingFeedStore = useFollowingFeedStore();
  const [commentCount, setCommentCount] = useState(item?.commentCount || 0);
  const [currentEntityCommented, setCurrentEntityCommented] = useState(
    (item?.currentEntityCommented && authStore.isAuthenticated) || false,
  );
  const [likeCount, setLikeCount] = useState(item?.likeCount || 0);
  const [currentEntityLiked, setCurrentEntityLiked] = useState(
    item?.currentEntityLiked,
  );
  const [visible, setVisible] = useState(false);

  const { getTooltipProps, setTooltipRef, setTriggerRef } = usePopperTooltip({
    placement: 'bottom',
    trigger: 'click',
    interactive: true,
    closeOnTriggerHidden: true,
    visible,
    onVisibleChange: (isVisible) => {
      setVisible(isVisible);
    },
  });

  const setFeedCount = (count, currentEntityCommented) => {
    setCommentCount(count);
    setCurrentEntityCommented(currentEntityCommented);
    uiStore.openModal('COMMENT', {
      commentProps: {
        purchaseId: purchase.id,
        feed: purchase,
        currentEntityCommented: currentEntityCommented,
        commentCount: count,
        setFeedCount,
        followingFeedStore: followingFeedStore,
      },
    });
  };

  const onCommentClick = () => {
    if (!authStore.isAuthenticated) {
      uiStore.openModal('CONFIRM', {
        message: 'Please Login or Signup to like this post.',
        action: () => {
          navigate('/login');
        },
        confirmText: 'Login/Signup',
        cancelText: 'Not Now',
        bodyMessage:
          'Create your own Cauze account and join our community of givers.',
      });
      return;
    }

    uiStore.openModal('COMMENT', {
      commentProps: {
        purchaseId: purchase.id,
        feed: purchase,
        currentEntityCommented,
        commentCount,
        setFeedCount,
        followingFeedStore: followingFeedStore,
      },
    });
  };

  return (
    <div className="pt-2.5 max-lg:-ml-5 max-lg:-mr-5 pb-[18px] bg-[#FFF0EB] flex flex-col gap-y-[14px] border-b-lightgray-d1d font-agenda">
      <div className="flex gap-x-2.5 pl-[22px] pr-[14px]">
        <div className="relative h-10 w-10">
          <Avatar
            noBorder
            className="host-avatar"
            entityType={host?.type}
            avatar={host?.avatarUrls}
            onClick={() => {
              navigate(
                getEntityUrl({
                  id: host.id,
                  entityType: host.type,
                }),
              );
            }}
          />
          <div className="absolute -bottom-0.5 -right-2 bg-cauze-primary rounded-full grid place-content-center h-[20px] w-[20px]">
            <PinIcon />
          </div>
        </div>
        <span className="my-auto text-[17px] leading-none">
          <a
            className="text-black my-auto font-bold"
            href={getEntityUrl({
              id: host.id,
              entityType: host.type,
            })}
          >
            {host?.name}
          </a>{' '}
          posted an update.
        </span>
        <div
          ref={setTriggerRef}
          className="inline-block ml-auto h-4 my-auto cursor-pointer"
        >
          <div className="text-lightgray-999">
            <ThreeDotsIcon />
          </div>
        </div>

        {visible && (
          <div
            ref={setTooltipRef}
            {...getTooltipProps({
              className:
                'tooltip-container tooltip-container-checkout-summary !p-0 !py-2 !border !border-solid !border-lightgray-e8e',
            })}
          >
            <div className="tooltip-body">
              <button
                className="font-agenda text-[16px] w-full p-2.5 text-center bg-transparent hover:bg-lightgray-e8e cursor-pointer border-none"
                onClick={() => {
                  setVisible(false);
                  uiStore.openModal('REPORT', {
                    reportProps: {
                      purchaseId: purchase.id,
                    },
                  });
                }}
              >
                Report
              </button>
              {event.currentEntityIsHost && (
                <button
                  className="font-agenda text-[16px] w-full p-2.5 text-center bg-transparent hover:bg-lightgray-e8e cursor-pointer border-none"
                  onClick={() => {
                    setVisible(false);
                    eventStore.deleteEventUpdate({
                      eventId: event.id,
                      purchaseId: purchase.id,
                    });
                  }}
                >
                  Delete
                </button>
              )}
            </div>
          </div>
        )}
      </div>
      {Boolean(purchase.image?.lg) && (
        <img className="w-full h-auto" src={purchase.image.lg} />
      )}
      <div className="text-[14px] leading-tight pl-[22px] pr-[14px]">
        {purchase.commentBody}
      </div>
      <div className="text-[12px] leading-tight pl-[22px] pr-[14px]">
        {moment(purchase.insertedAt).format('MMM DD, YYYY')}
      </div>
      <div className="max-lg:px-[20px] xl:pl-5 lg:max-w-[153px]">
        <FeedItemActions
          item={item}
          commentCount={commentCount}
          onCommentClick={onCommentClick}
          currentEntityCommented={currentEntityCommented}
          currentEntityLiked={currentEntityLiked}
          authStore={authStore}
          onLike={() => {
            setCurrentEntityLiked(!currentEntityLiked);
            setLikeCount(likeCount + !currentEntityLiked ? 1 : -1);
            onLike({
              feedId: item?.id,
              purchaseId: purchase?.id,
              currentEntityLiked: currentEntityLiked,
            });
          }}
          onShareClick={onShareClick}
          likeCount={likeCount}
        />
      </div>
    </div>
  );
};

export default inject(
  'authStore',
  'uiStore',
  'eventStore',
)(observer(EventHostUpdates));
