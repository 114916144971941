import React, { useState, useEffect, useRef } from 'react';
import { observer, inject } from 'mobx-react';
import { toJS } from 'mobx';
import moment from 'moment';
import { isEmpty } from 'lodash';
import 'react-tippy/dist/tippy.css';
import cx from 'classnames';
import ReactPlayer from 'react-player';
import { Helmet } from 'react-helmet-async';

import withUserContext from 'behaviors/withUserContext';
import * as events from 'util/events/cauze';
import { setShouldScrollToFeed, scrollToFeedIfNeeded } from 'util/scrollUtils';

import CharityListNew from 'components/CharityList/CharityListNew';
import Avatar from 'components/Avatar/Avatar';
import Currency from 'components/Currency/Currency';
import CauzeSpinner from 'components/CauzeSpinner/CauzeSpinner';
import FeedItem from 'components/FeedItem/FeedItem';
import ShareModal from 'components/modals/ShareModal/ShareModal';

import Button from 'components/Button/Button';

import { convertMarkdownToFormatted, sanitizer } from 'util/stringUtils';
import SectionHeader from 'components/SectionHeader/SectionHeader';
import CauzeButton from 'components/CauzeButton/CauzeButton';
import calendarGrey from 'assets/images/icons/calendar/calendar-grey.svg';
import shareGrey from 'assets/images/icons/share/share-grey.svg';
import threeDotsHollow from 'assets/images/icons/dots/three-dots-hollow.svg';
import CauzePinButton from 'components/CauzePinButton/CauzePinButton';
import convertFeedToV1 from 'util/feed/convertFeedToV1';
import useMobile from 'hooks/useMobile';
import { shareImage } from 'util/share';
import EventHostUpdates from 'components/EventHostUpdates/EventHostUpdates';
import useCheckoutStore from 'stores/CheckoutStoreV2';

import { navigate } from '@reach/router';

const getJoinerAvatar = (purchase) => {
  if (purchase.company) return purchase.company.avatarUrls;
  else if (purchase.donorProject) return purchase.donorProject.avatarUrls;
  else return purchase.user.avatarUrls;
};
// Remember to test unauthed cases on this view!

const HeaderImage = ({ event, eventStore, isAuthenticated }) => {
  const hasVideo =
    event?.previewUrl &&
    (event?.previewUrl.includes('youtu') ||
      event?.previewUrl.includes('vimeo'));

  if (hasVideo) {
    return (
      <div className="video-container">
        <div className="event-video">
          <ReactPlayer
            width="100%"
            height="100%"
            controls
            url={event.previewUrl}
            config={{
              vimeo: {
                playsinline: true,
              },
            }}
          />
        </div>
      </div>
    );
  }

  if (event?.image) {
    return (
      <img
        className="header-image"
        src={event?.image?.full || event?.image?.lg}
      />
    );
  }

  return <div className="header-image linear-gradient-image" />;
};

const FuturePhilanthropyView = ({
  eventStore,
  profileStore,
  authStore,
  userContext,
  purchaseId,
  uiStore,
  activeEntity,
  feedSectionRef,
}) => {
  const eventId = eventStore.eventData.id;

  useEffect(() => {
    eventStore.getEventPurchasers({
      id: eventId,
      purchaseId,
    });
    eventStore.getEventUpdates({ id: eventId });
    eventStore.getEventFeed({ id: eventId, purchaseId, sortFeatured: false });
  }, [eventId, purchaseId]);

  const hostDescriptionRef = useRef();
  const hostDescriptionContainerRef = useRef();
  const [showShareModal, setShowShareModal] = useState(false);
  const [showMatchInviteModal, setShowMatchInviteModal] = useState(false);

  const [showFullDescription, setShowFullDescription] = useState(false);
  const [showAllUpdates, setShowAllUpdates] = useState(false);

  const { isMobile } = useMobile();

  let parsedEventId =
    decodeURIComponent(eventId).split('?').length > 1 &&
    decodeURIComponent(eventId).split('?')[0];
  const sanitizedEventId = parsedEventId || eventId;

  const event = eventStore.eventData;
  const feed = eventStore.eventFeed;
  const updates = eventStore.hostUpdates;

  const onLike = ({ feedId, purchaseId, currentEntityLiked }) => {
    if (!authStore.isAuthenticated) {
      uiStore.openModal('CONFIRM', {
        message: 'Please Login or Signup to like this post.',
        action: () => {
          navigate('/login');
        },
        confirmText: 'Login/Signup',
        cancelText: 'Not Now',
        bodyMessage:
          'Create your own Cauze account and join our community of givers.',
      });
      return;
    }

    const feedItem = feed?.find(
      (feedItem) => feedItem?.activityPurchase?.id === feedId,
    );

    if (feedItem) {
      eventStore.like({
        like: !feedItem.currentEntityLiked,
        id: feedId,
        purchaseId: feedItem.activityPurchase?.id,
        eventId: eventId,
        userContext: userContext,
      });
    } else if (purchaseId) {
      eventStore.like({
        like: !currentEntityLiked,
        id: feedId,
        purchaseId,
        eventId,
        userContext,
      });
    }
  };

  const purchases = eventStore.purchasers;
  const hosts = event.hosts || [];

  let isUserBio = false;
  let hostDescription = '';

  if (hosts[0]?.type === 'COMPANY') {
    hostDescription = hosts[0]?.company?.description;
  } else if (hosts[0]?.type === 'USER') {
    hostDescription = hosts[0]?.user?.bio;
    isUserBio = true;
  } else if (hosts[0]?.type === 'CHARITY') {
    hostDescription = hosts[0]?.project?.description;
  }

  let isRecipientCharity;

  if (userContext?.charityId) {
    isRecipientCharity =
      event.charities.filter((charity) => charity.id === userContext.charityId)
        .length > 0;
  }

  const eventMatches = Array.isArray(event.matches) ? event.matches : [];

  const showMatch =
    eventMatches.length > 0 &&
    eventMatches[0].matchSponsors.length > 0 &&
    eventMatches[0].totalRemaining > 0;

  let matchMultiplier =
    eventMatches.length && showMatch
      ? eventMatches.reduce((a, b) => Math.max(a, b.multiplier), 1)
      : 1;
  let matchSponsorCount =
    eventMatches.length && showMatch
      ? eventMatches.reduce((a, b) => a + (b.matchSponsors?.length || 0), 0)
      : 0;

  const isExpired =
    Boolean(event.endDate) && moment(event.endDate).isBefore(moment());

  const onJoinersClick = () => {
    uiStore.openModal('ENTITY_LIST', {
      title: `${event.purchaserCount} Givers`,
      showFollow: true,
      entities: eventStore.purchasers,
      buttonLabel: 'Follow',
      isSelf: false,
      store: eventStore,
      isAuthenticated: authStore.isAuthenticated,
    });
  };

  const checkoutStore = useCheckoutStore();

  const scrollToFeed = () => {
    if (feedSectionRef.current) {
      feedSectionRef.current.scrollIntoView({ behavior: 'smooth' });
      return;
    }
    setShouldScrollToFeed();
  };

  useEffect(() => {
    scrollToFeedIfNeeded(feedSectionRef);
  }, [feedSectionRef.current]);

  const onNavToCheckout = () => {
    events.cauzeCheckoutClick(+event.id === -1 ? null : event.id);
    uiStore.openModal('CHECKOUT', {
      checkoutType: checkoutStore.CHECKOUT_TYPE.DONATION,
      onCheckoutSuccess: () => {
        profileStore.getProfile();
      },
      isCauzePage: true,
      scrollToFeed: scrollToFeed,
      ...toJS(event.config),
      charityIds: event.charities.map((c) => c.id).filter((c) => c),
      eventId: +event.id === -1 ? null : event.id,
      joinPurchaseId: event.joinPurchaseId,
      hideAmount: uiStore.savedUiState?.userToken?.gift?.hideAmount,
      giftToken: uiStore.savedUiState.userToken?.token,
      giftAmount: uiStore.savedUiState.userToken?.gift.amount,
      email: uiStore.savedUiState.userToken?.email || profileStore?.data?.email,
      matchIds: eventMatches?.length
        ? eventMatches.map((eventMatch) => eventMatch?.id)
        : undefined,
      matches: eventMatches,
      initiatorId: uiStore.savedUiState.userToken?.gift?.initiatorCompany?.id,
      firstName: activeEntity?.firstName,
      lastName: activeEntity?.lastName,
      zip: activeEntity?.zip,
      donationAmount: 500,
      referrerContext: uiStore.savedUiState?.checkoutReferrer?.referrerContext,
      joinedEntity: hosts?.[0],
    });
  };

  const openCauzeUpdateModal = () => {
    uiStore.openModal('UPDATE_CAUZE_MODAL', {
      commentProps: {
        isRecipientCharity,
        event,
        parsedEventId,
        purchaseId,
        userContext,
        eventStore,
      },
    });
  };

  const onShareClick = () => {
    if (isMobile && navigator.share) {
      if (event.image) {
        shareImage({
          url: event.shareLink,
          imageUrl: event.image.full,
          title: event.name,
          text: event.description,
        }).catch((err) => {
          uiStore.showNotification({
            body: `${err}`,
            type: 'ERROR',
          });
          setShowShareModal(true);
        });
      } else {
        navigator.share({
          title: event.name,
          url: event.shareLink,
        });
      }
    } else {
      setShowShareModal(true);
    }
  };

  const onProfileViewClick = (host) => {
    uiStore.openModal('PROFILE_MODAL', {
      id: host.id,
      entityType: host.type,
    });
  };

  return (
    <div className="event-view flex-column">
      <Helmet>
        {event.image?.full && (
          <meta property="og:image" content={event.image.full} />
        )}
      </Helmet>
      <div className="event-view-container flex-column">
        <ShareModal
          isOpen={showShareModal}
          onToggleClose={() => setShowShareModal(false)}
          shareUrl={event.shareLink}
          shareTitle={event.name}
        />
        <ShareModal
          isOpen={showMatchInviteModal}
          onToggleClose={() => setShowMatchInviteModal(false)}
          shareUrl={eventMatches[0]?.shareLink}
          shareTitle={'Copy Match Invite Link'}
        />

        <div className="event-data-container">
          <div className="event-title-container">
            <div className="title-container">
              <h1 className="title-text">{event.name}</h1>
            </div>
            <div className="subtitle-container">
              <div className="subtitle-host-container flex gap-1">
                <text className="hosted-by-text">{`Hosted by `}</text>
                <text>
                  {hosts.map((host) => (
                    <div
                      onClick={() => onProfileViewClick(host)}
                      key={`${host.id}`}
                      className="cursor-pointer"
                    >
                      <span className="hosted-by-name">{`${host.name}`}</span>
                    </div>
                  ))}
                </text>
              </div>
              {showMatch && (
                <div className="match-details-container">
                  <text className="subtitle-pipe">{` | `}</text>
                  <text>
                    {` ${matchMultiplier} x Match from ${matchSponsorCount} sponsors`}
                  </text>
                </div>
              )}
              {!!event?.endDate && (
                <div className="event-end-date-container">
                  <text className="subtitle-pipe">{` | `}</text>
                  <img className="subtitle-calendar" src={calendarGrey} />
                  <text>
                    {`Ends: ${moment(event.endDate).format('D MMM YYYY')}`}
                  </text>
                </div>
              )}
            </div>
          </div>
          <div className="new-event-header">
            <div className="header-image-container">
              {Boolean(authStore.isAuthenticated) && (
                <CauzePinButton
                  pinned={event?.currentEntityHasPinned}
                  onPin={() =>
                    eventStore.pinEvent({
                      eventId: event?.id,
                      pinned: !event?.currentEntityHasPinned,
                    })
                  }
                />
              )}
              <HeaderImage event={event} />
            </div>
            <div className="header-info-container">
              <div className="header-info-flex-container">
                <div className="header-description-container">
                  {hosts.map((host) => (
                    <div
                      className={cx('host-avatar-container cursor-pointer', {
                        'no-avatar': !host.avatarUrls,
                      })}
                      onClick={() => onProfileViewClick(host)}
                      key={`${host.id}`}
                    >
                      {host.id && (
                        <Avatar
                          className="host-avatar"
                          entityType={host.type}
                          md
                          avatar={host.avatarUrls}
                          key={`${host.id}`}
                        />
                      )}
                      <div>
                        <text className="event-name">{event?.name}</text>
                        <div className="host-name-container">
                          <text className="host-name">{`by:`}</text>
                          &nbsp;
                          <text className="host-name bold">{`${host.name}`}</text>
                        </div>
                      </div>
                      {(!!event?.endDate || showMatch) && (
                        <text className="match-details-text">
                          {`${
                            showMatch
                              ? `${matchMultiplier} x Match from ${matchSponsorCount} sponsors`
                              : ''
                          } `}
                          {`${!!event?.endDate && showMatch ? ` | ` : ''}`}
                          {`${
                            !!event?.endDate
                              ? `Ends: ${moment(event.endDate).format(
                                  'D MMM YYYY',
                                )}`
                              : ''
                          }`}
                        </text>
                      )}
                    </div>
                  ))}
                  <div
                    ref={hostDescriptionContainerRef}
                    className={cx('header-description-text-container', {
                      expanded: showFullDescription,
                      'hide-on-mobile': isUserBio,
                    })}
                  >
                    <text
                      ref={hostDescriptionRef}
                      className="header-description-text"
                    >
                      {hostDescription}
                    </text>
                    {!showFullDescription &&
                      hostDescriptionContainerRef.current?.clientHeight <
                        hostDescriptionRef.current?.offsetHeight && (
                        <div
                          class="read-more-link"
                          onClick={() => setShowFullDescription(true)}
                        >
                          Read More...
                        </div>
                      )}
                  </div>
                </div>
              </div>
              <div className="header-action-container">
                <div className="amount-raised-container">
                  {event.purchaserCount >= 3 ? (
                    <text className="amount-raised-text">
                      <Currency amount={event.current} />
                      <span className="raised-text">{' RAISED'}</span>
                    </text>
                  ) : (
                    <text className="amount-raised-text">Give Now</text>
                  )}
                </div>
                <div className="joiners">
                  <text className="giver-text">
                    {`${event.purchaserCount}`}
                    <br />
                    Givers:
                  </text>
                  <div className="avatar-container">
                    {purchases.slice(0, 6).map((purchase, index) => (
                      <Avatar
                        className="joiner"
                        key={index}
                        index={index}
                        avatar={getJoinerAvatar(purchase)}
                        onClick={onJoinersClick}
                      />
                    ))}
                    {purchases.length > 6 && (
                      <button
                        className="more-givers-avatar"
                        onClick={onJoinersClick}
                      >
                        <img src={threeDotsHollow} />
                      </button>
                    )}
                  </div>
                </div>
                {isExpired ? (
                  <div className="expired-text">This Cauze has Ended.</div>
                ) : isEmpty(toJS(uiStore.savedUiState.userToken)) ? (
                  <div>
                    <CauzeButton
                      onClick={onNavToCheckout}
                      children={'Give/Join'}
                      className="event-join-button"
                    />
                  </div>
                ) : (
                  <Button
                    onClick={() => {
                      uiStore.openModal('CHECKOUT', {
                        checkoutType:
                          checkoutStore.CHECKOUT_TYPE.GIFTED_DONATION,
                        frame: checkoutStore.FRAME.GIFT_CHECKOUT,
                        charityIds: event.charities.map((c) => c.id),
                        eventId: +event.id === -1 ? null : event.id,
                        joinPurchaseId: event.joinPurchaseId,
                        hideAmount:
                          uiStore.savedUiState?.userToken?.gift?.hideAmount,
                        giftToken: uiStore.savedUiState.userToken?.token,
                        amount: uiStore.savedUiState.userToken?.gift.amount,
                        email: uiStore.savedUiState.userToken?.email,
                        matchIds: eventMatches?.length
                          ? eventMatches.map((eventMatch) => eventMatch?.id)
                          : undefined,
                        initiatorId:
                          uiStore.savedUiState.userToken?.gift?.initiatorCompany
                            ?.id,
                      });
                    }}
                    className="event-join-button"
                  >
                    Give
                  </Button>
                )}
                <div className="event-details-container">
                  <div
                    id="inspire-button"
                    className="inspire-button"
                    onClick={onShareClick}
                  >
                    <img className="share-grey" src={shareGrey} />
                    <text className="inspire-text">Share</text>
                  </div>
                </div>
                {+activeEntity.id === event.hosts[0].id && (
                  <div className="event-details-container">
                    <div
                      className="inspire-button"
                      onClick={openCauzeUpdateModal}
                    >
                      <text className="inspire-text">UPDATE</text>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <CharityListNew
              onLearnMore={(id) => onProfileViewClick({ id, type: 'CHARITY' })}
              charities={event.charities}
              eventId={+event.id === -1 ? null : event.id}
              hideShadow
              title={'Supporting:'}
              rounded
            />
          </div>
          <div className="event-content-container">
            <div className="description-container">
              {event.description && (
                <>
                  <text className="overview-text">Overview</text>
                  <div
                    className="description-body"
                    dangerouslySetInnerHTML={{
                      __html: sanitizer(
                        convertMarkdownToFormatted(event.description),
                        {
                          ADD_ATTR: ['target'],
                        },
                      ),
                    }}
                  ></div>
                </>
              )}
            </div>
            {showMatch && (
              <div className="right-content-container">
                {eventMatches.map((match) =>
                  match?.matchSponsors?.map((matchSponsor) => (
                    <div
                      onClick={() => {
                        onProfileViewClick({
                          id: matchSponsor?.id,
                          type: matchSponsor?.type,
                        });
                      }}
                      className="match-container cursor-pointer"
                    >
                      <div className="match-avatar-container">
                        <Avatar
                          noBorder={true}
                          className="match-avatar"
                          entityType={matchSponsor?.type}
                          avatar={matchSponsor?.avatarUrls}
                          key={`${match.id}`}
                        />
                      </div>
                      <div className="match-info-container">
                        <text className="match-sponsor-name-text">
                          {matchSponsor?.name}
                        </text>
                        <br />
                        <text className="match-multiplier-text">
                          {`${match?.multiplier}x MATCH`}
                        </text>
                        <div className="match-amount-text-container">
                          <Currency amount={matchSponsor?.remaining || 0} />
                          <span>{` of `}</span>
                          <Currency amount={matchSponsor?.matchTotal || 0} />
                          <span>{` remaining`}</span>
                        </div>
                      </div>
                    </div>
                  )),
                )}
              </div>
            )}
          </div>
          <div className="event-feed-container">
            <div>
              {Boolean(updates?.length) && (
                <div>
                  <div className="event-host-update mb-2.5">
                    <h6 className="h-[60px] font-agenda-black text-[33px] leading-[60px] text-left mb-3">
                      Host Updates
                    </h6>
                    {updates
                      .slice(0, showAllUpdates ? undefined : 1)
                      .map((item) => (
                        <EventHostUpdates
                          event={event}
                          key={item.id}
                          host={hosts?.[0]}
                          item={item}
                          onLike={onLike}
                          onShareClick={onShareClick}
                        />
                      ))}
                  </div>
                  {updates?.length > 1 && (
                    <div className="flex justify-center">
                      <button
                        className="text-cauzeorange underline text-[17px] font-bold bg-transparent border-none cursor-pointer mx-auto"
                        onClick={() => {
                          setShowAllUpdates(!showAllUpdates);
                        }}
                      >
                        {!showAllUpdates ? 'VIEW ALL' : 'HIDE'}
                      </button>
                    </div>
                  )}
                </div>
              )}
              <div className="recent-giving-activity">
                <SectionHeader
                  title="Recent Giving Activity"
                  feedSectionRef={feedSectionRef}
                />
              </div>
              {!eventStore.feedLoading && eventStore.eventFeed ? (
                <div className="feed-wrapper">
                  <div className="feed flex-align-center full-width">
                    {feed?.map(convertFeedToV1).map((item, index) => (
                      <FeedItem
                        isCauzePage
                        key={`${item.date}-${item.actor.id}-${index}`}
                        item={{
                          ...item,
                          event: { shareLink: item.event?.shareLink },
                        }}
                        mobileViewFeedItemPadding
                        eventTitle={event.name}
                        onLike={onLike}
                        shareLink={
                          item.event?.shareLink ||
                          item.activityPurchase?.shareLink
                        }
                        onCheckoutSuccess={() => {
                          profileStore.getProfile();
                        }}
                        scrollToFeed={scrollToFeed}
                        index={index}
                        uiStore={uiStore}
                      />
                    ))}
                  </div>
                  {feed?.length % 20 === 0 &&
                    feed?.length > 0 &&
                    (feed?.length === eventStore.currentPage * 20 ||
                      eventStore.loadingMore) && (
                      <CauzeButton
                        isSubmitting={eventStore.loadingMore}
                        onClick={() =>
                          eventStore.loadMore({
                            id: sanitizedEventId,
                            purchaseId,
                          })
                        }
                        style={{ marginTop: '5rem' }}
                      >
                        Load More Activity
                      </CauzeButton>
                    )}
                </div>
              ) : (
                <CauzeSpinner />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withUserContext(
  inject(
    'eventStore',
    'profileStore',
    'uiStore',
    'matchStore',
    'authStore',
  )(observer(FuturePhilanthropyView)),
);
