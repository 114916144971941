import React, { useState, useRef } from 'react';
import { observer, inject } from 'mobx-react';
import classNames from 'classnames';
import TextareaAutosize from 'react-textarea-autosize';

import ErrorModal from 'components/modals/ErrorModal/';
import CauzeSpinner from 'components/CauzeSpinner/CauzeSpinner';
import { ReactComponent as ImageIcon } from 'assets/images/icons/image/image.svg';
import CommentInputImageUploadModal from './CommentInputImageUploadModal';
import { ReactComponent as CloseIcon } from 'assets/images/icons/close/close.svg';
import placeholder from 'assets/images/placeholders/placeholder-event-image.png';

const LoadingImage = () => (
  <div className="image-loading-area">
    <CauzeSpinner size="4rem" />
  </div>
);

const CommentInput = ({
  comment,
  setComment,
  eventStore,
  uploadingImage,
  setUploadingImage,
}) => {
  const [previewImageUrl, setPreviewImageUrl] = useState();
  const [upImg, setUpImg] = useState(placeholder);
  const inputRef = useRef(null);
  const imageInputRef = useRef(null);

  const [showImageUploadModal, setShowImageUploadModal] = useState(false);
  const [showImageUploadError, setShowImageUploadError] = useState(false);
  const [showFileError, setShowFileError] = useState(false);
  const [isChanged, setIsChanged] = useState(false);

  const handleCloseImageUploadError = () => {
    setShowImageUploadError(!showImageUploadError);
  };

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      if (e.target.files[0].size > 3000000) {
        setShowFileError(
          'File is too large. Please select a file less than 3MB.',
        );
        return;
      }
      setIsChanged(true);
      setShowImageUploadModal(true);
      const reader = new FileReader();
      reader.addEventListener('load', () => setUpImg(reader.result));
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  return (
    <div>
      <div className="is-hidden">
        <input
          ref={imageInputRef}
          type="file"
          accept="image/*"
          onChange={onSelectFile}
        />
      </div>

      <CommentInputImageUploadModal
        updateImageUrl={setPreviewImageUrl}
        setUploadingImage={setUploadingImage}
        isActive={showImageUploadModal}
        onToggleClose={() => setShowImageUploadModal(false)}
        cropRatio={[1, 1]}
        src=""
        noPlaceholder
        store={eventStore}
        isChanged={isChanged}
        setIsChanged={setIsChanged}
        upImg={upImg}
        setUpImg={setUpImg}
        showFileError={showFileError}
        setShowFileError={setShowFileError}
      />

      <ErrorModal
        onToggleClose={handleCloseImageUploadError}
        errorText="There was an issue uploading the image. Support has been notified."
        isOpen={showImageUploadError}
      />

      <div style={{ width: '100%' }} className="flex-column flex-space-between">
        <div className="comment-area-container">
          {uploadingImage && <LoadingImage />}
          <div className="relative mt-5 border pl-2 pb-2 border-solid border-black overflow-hidden rounded-[10px]">
            <ImageIcon
              className="absolute top-3.5 right-2 cursor-pointer"
              onClick={() => {
                imageInputRef.current.click();
              }}
            />
            <TextareaAutosize
              ref={inputRef}
              className={classNames(
                'overflow-hidden focus:outline-none pt-4 w-full border-none resize-none max-sm:text-lg text-agenda pr-6',
                {
                  'is-image': previewImageUrl,
                },
              )}
              value={comment}
              onChange={(ev) => {
                setComment(ev.target.value);
              }}
              placeholder="Share your thoughts..."
              maxRows={4}
            />
            {previewImageUrl && (
              <div className="image-container">
                <div
                  className="remove-container relative h-[100px] w-[100px] bg-cover bg-center rounded-[10px] filter hover:brightness-75 group"
                  style={{
                    backgroundImage: `url(${previewImageUrl})`,
                  }}
                >
                  <button
                    title="Remove?"
                    onClick={(ev) => {
                      ev.preventDefault();
                      setPreviewImageUrl('');
                    }}
                    className="absolute w-full h-full text-center bg-transparent border-none cursor-pointer hidden group-hover:block"
                  >
                    <CloseIcon />
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default inject('authStore', 'eventStore')(observer(CommentInput));
